import { gql } from 'apollo-angular';
import { errorsTypeDef } from '../../shared/services/graphql/errors/errors-type-def';

export const taskDetailQuery = gql`
  query taskById($id: String!) {
    task {
      byId(input: {
        id: $id
      }) {
        output {
          id
          allowOnlineTaskInspectionAccess
          canBeAssumed
          claimSettlementType {
            id
            name
          }
          centralEvent {
            id
            centralEventNumber
          }
          damageEvent {
            id
            claimNumber
          }
          taskFileGroups {
            id
            source
            state
            downloadAllFilesUrl
            includeToExportByDefault
            taskFiles {
              id
              name
              src
              size
              createdAt
              taskFileGroupId
              taskFileGroup {
                id
              }
            }
            taskFileGroupType {
              id
              name
              type
            }
            messages {
              id
              text
              authorName
              createdAt
              cancelledAt
            }
          }
          image {
            id
            src
            thumbnails {
              type
              src
            }
          }
          insuranceCompany {
            id
            name
          }
          internalCode
          partner {
            id
            name
            code
            allowedOnlineTaskInspection
          }
          state {
            id
            name
          }
          stateReason
          carVin
          carType {
            id
            name
          }
          carYearManufactured
          carLicensePlate
          carNonstandardLicensePlate
          carModel
          assignedTo {
            id
            name
          }
          canBeNotExecuted
          canBeEdited
          canBeResolverChanged
          canBeReviserChanged
          canBeRemoved
          callerName
          callerPhone
          callerEmail
          claimNumber
          clientName
          clientPhone
          clientEmail
          clientType
          clientIdentificationCode
          documentationCompletedDate
          documentationCompletedBy {
            username
            type
          }
          excessPrice
          excessPercent
          damageType {
            id
            name
          }
          dateOfLoss
          description
          externalCode
          taskCalculationFiles {
            id
            name
            src
            size
            extension
            thumbnails {
                type
                width
                height
                src
            }
          }
          taskFiles {
            id
            name
            src
            size
            extension
            createdAt
            thumbnails {
              type
              width
              height
              src
            }
            taskFileGroupId
            taskFileGroup {
              id
            }
          }
          taskInternalFiles {
            id
            name
            src
            size
            extension
            updatedAt
            thumbnails {
                type
                width
                height
                src
            }
          }
          note
          internalNote
          reviser {
            id
            name
          }
          revisionMessages {
            id
            text
            reviser {
              id
              name
            }
            createdAt
          }
          updatedAt
          changedAt
          tags {
            text
          }
          serviceNumber
          damageNumber
          leasingApprovalNumber
          downloadFilteredFilesUrl
          downloadPdfProtocolUrl
          downloadPdfProtocolWithoutLogoUrl
          downloadPdfDamageInspectionProtocolUrl
          downloadPdfDamageInspectionProtocolWithoutLogoUrl
          downloadPhotosUrl
          smsUploadToken {
            id
            type
            validTo
            usedAt
            data
            createdAt
          }
          carEquipmentCategory {
            id
            identification
            name
            description
            carEquipment {
              id
              type
              identification
              name
              description
              taskCarHasCarEquipmentId
              value
            }
          }
          carStateEvaluationType {
            id
            name
          }
          taskInspectionType {
            id
            name
          }
          taskPhotodocumentation {
            id
            identification
            name
            taskHasTaskPhotodocumentationId
            value
          }
          technicalCertificateVehicleOwnerName
          photographedName
          wroteName
          executionDate
          damageNote
          insuranceContractNumber
          leasingOrCredit
          carYearOperationFrom
          carTechnicalCertificateValidityDate
          carMileage
          carDoors
          carColor
          carEngine {
            id
            carEngineFuel {
              id
              name
            }
            volume
            powerKw
          }
          carPartGroupStates {
            id
            name
            taskHasCarPartGroupStateId
            value
          }
          carPartRepairs {
            id
            name
            carPartType {
              id
              name
              identification
            }
            repairType {
              id
              name
              short
            }
          }
          carTires {
            id
            vendor
            model
            depth
            diameter
            width
            profile
            carTirePosition {
              id
              name
              short
            }
            carTireDedication {
              id
              name
              short
            }
          }
          carNote
            vatPayer
            claimLiquidation {
              id
              note
              claimLiquidationType {
                id
                name
              }
            }
            taskInspection {
              id
              place
              note
              secondInspection
            }
            createdAt
            createdBy {
                username
                type
            }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const employeeByMeQuery = gql`
  query EmployeesByMe {
    employee {
      byMe {
        output{
          id
          roles
          accessibleEmployees {
            id
            name
            roles
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }`;

export const uploadTaskFileMutation = gql`
  mutation taskFileUpload (
  $taskId: String!
  $taskFileGroupId: String
  $name: String!
  $content: String!
  $extension: String!
  ) {
    taskFile{
      upload(input: {
        taskId: $taskId
        taskFileGroupId: $taskFileGroupId
        file: {
          name: $name
          content: $content
          extension: $extension
        }
      }) {
        output {
          id
          name
          type
          src
          thumbnails {
            type
            src
          }
          extension
          taskId
          taskFileGroupId
          taskFileGroup {
            id
            taskId
            taskFileGroupTypeId
            taskFileGroupType {
              id
              name
              type
              source
            }
            source
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const removeTaskFileGroupMutation = gql`
  mutation removeFileGroup(
  $id: String!
  ){
    taskFileGroup{
      removePermanently(input: {
        id: $id
      }){
        output {
          id
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const setFileGroupMutation = gql`
  mutation taskFileUpload (
  $fileId: String!
  $taskFileGroupId: String
  ) {
    taskFile{
      setTaskFileGroup(input: {
        id: $fileId
        taskFileGroupId: $taskFileGroupId
      }) {
        output {
          id
          taskFileGroupId
          taskFileGroup {
            id
            taskId
            taskFileGroupTypeId
            taskFileGroupType {
              id
              name
              type
              source
            }
            source
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const approveTaskFileGroupMutation = gql`
  mutation approveFileGroup(
  $id: String!
  ){
    taskFileGroup{
      approve(input: {
        id: $id
      }){
        output {
          id
          state
          messages {
            id
            text
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const resetTaskFileGroupMutation = gql`
  mutation resetFileGroup(
  $id: String!
  ){
    taskFileGroup{
      reset(input: {
        id: $id
      }){
        output {
          id
          taskId
          taskFileGroupTypeId
          taskFileGroupType {
            id
            name
            type
            source
          }
          source
          taskFiles {
            id
            taskId
            taskFileGroupId
            taskFileGroup {
              id
              taskId
              taskFileGroupTypeId
              taskFileGroupType {
                id
                name
                type
                source
              }
              source
            }
          }
          state
          messages {
            id
            text
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const cancelCompletionTaskFileGroupMutation = gql`
  mutation cancelCompletionFileGroup(
  $id: String!
  ){
    taskFileGroup{
      cancelCompletion(input: {
        id: $id
      }){
        output {
          id
          taskId
          taskFileGroupTypeId
          taskFileGroupType {
            id
            name
            type
            source
          }
          source
          taskFiles {
            id
            taskId
            taskFileGroupId
            taskFileGroup {
              id
              taskId
              taskFileGroupTypeId
              taskFileGroupType {
                id
                name
                type
                source
              }
              source
            }
          }
          state
          messages {
            id
            text
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const setDocumentsMissingMutation = gql`
  mutation setDocumentsMissing(
  $id: String!,
  $message: String!
  ){
    taskFileGroup{
      setDocumentsMissing(input: {
        id: $id
        message: $message
      }){
        output {
          id
          taskId
          state
          messages {
            id
            text
          }
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const createTaskFileGroupMutation = gql`
  mutation createFileGroup(
  $taskId: String!
  $name: String!
  $type: String!
  ){
    taskFileGroup {
      create(input: {
        name: $name
        taskId: $taskId
        type: $type
      }) {
        output {
          id
          taskId
          taskFileGroupTypeId
          taskFileGroupType {
            id
            name
            type
            source
          }
          source
          taskFiles {
            id
            taskId
            taskFileGroupId
            taskFileGroup {
              id
              taskId
              taskFileGroupTypeId
              taskFileGroupType {
                id
                name
                type
                source
              }
              source
            }
          }
          state
          messages {
            id
            text
          }
          downloadAllFilesUrl
          includeToExportByDefault
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;

export const setDefaultTaskFileMutation = gql`
  mutation setDefaultTaskFile(
    $id: String!
  ) {
    taskFile {
      setDefault(input: {
        id: $id
      }) {
        output {
          id
          taskId
          name
          size
          extension
          createdAt
          updatedAt
          removedAt
          type
          src
        }
        errors {
          ${errorsTypeDef}
        }
      }
    }
  }
`;
